/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // FUNCTION FOR SCROLLING ONE PAGE MENU
  // jQuery('#main_menu li a').on('click', function () {
  //   var the_id = jQuery(this).attr("href");
  //   jQuery('html, body').animate({
  //       scrollTop: jQuery(the_id).offset().top - 80
  //   }, 'slow');
  //   jQuery(".navbar-collapse").collapse('hide');
  //   jQuery('#main_menu li a').removeClass("active");
  //   jQuery(this).addClass("active");
  //   return false;
  // });

  jQuery(function ($) {
    //Create the cookie object
    var cookieStorage = {
      setCookie: function setCookie(key, value, time, path) {
        var expires = new Date();
        expires.setTime(expires.getTime() + time);
        var pathValue = "";
        if (typeof path !== "undefined") {
          pathValue = "path=" + path + ";";
        }
        document.cookie =
          key +
          "=" +
          value +
          ";" +
          pathValue +
          "expires=" +
          expires.toUTCString();
      },
      getCookie: function getCookie(key) {
        var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
        return keyValue ? keyValue[2] : null;
      },
      removeCookie: function removeCookie(key) {
        document.cookie = key + "=; Max-Age=0; path=/";
      },
    };

    // green work at hasson slick slider mobile
    $(".green-work-at-hasson-slider").slick({
      dots: true,
      arrows: false,
      slidesToShow: 1,
      mobileFirst: true,
      fade: true,
      responsive: [
        {
          breakpoint: 500,
          slidesToShow: 2,
        },
        {
          breakpoint: 993,
          settings: "unslick",
        },
      ],
    });

    $(window).on("resize", function () {
      $(".green-work-at-hasson-slider").slick("resize");
    });

    //Click on dark mode icon. Add dark mode classes and wrappers. Store user preference through sessions
    $(".wpnm-button").click(function () {
      //Show either moon or sun
      $(".wpnm-button").toggleClass("active");
      //If dark mode is selected
      if ($(".wpnm-button").hasClass("active")) {
        //Add dark mode class to the body
        $("body").addClass("dark-mode");
        cookieStorage.setCookie("yonkovNightMode", "true", 2628000000, "/");
      } else {
        $("body").removeClass("dark-mode");
        setTimeout(function () {
          cookieStorage.removeCookie("yonkovNightMode");
        }, 100);
      }
    });

    //Check Storage. Display user preference
    if (cookieStorage.getCookie("yonkovNightMode")) {
      $("body").addClass("dark-mode");
      $(".wpnm-button").addClass("active");
    }

    /* ==========================================
    Header sticky on scroll
    ========================================== */
    $(window).on("scroll load", function () {
      if (
        $(".announcement-bar").length &&
        $(window).scrollTop() >= $(".announcement-bar").height()
      ) {
        $(".header").addClass("sticky");
        $(".announcement-bar").css("transform", "translateY(-50%)");
      } else if ($(window).scrollTop() >= $(".header").height()) {
        $(".header").addClass("sticky");
        $(".announcement-bar").css("transform", "translateY(-50%)");
      } else {
        $(".header").removeClass("sticky");
        $(".announcement-bar").css("transform", "translateY(0)");
      }
    });

    $(".menu-toggle").on("click", function () {
      $("body").toggleClass("overflow-hidden");
    });

    $("#apply-here, #apply-here-mobile").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      $(".form-hidden").slideToggle();

      $("html, body").animate(
        {
          scrollTop: $(".form-hidden").offset().top - 100,
        },
        1200
      );
    });
  });
})(jQuery); // Fully reference jQuery after this point.
